 .sidenav {
     height: 100%;
     width: 0;
     position: fixed;
     z-index: 1;
     top: 0;
     left: 0;
     background-color: #111;
     overflow-x: hidden;
     /* Disable horizontal scroll */
     padding-top: 60px;
     transition: 0.5s;
 }

 .sidenav a {
     padding: 8px 8px 8px 32px;
     text-decoration: none;
     font-size: 15px;
     color: #818181;
     display: block;
     transition: 0.3s;
 }

 .sidenav a:hover {
     color: white;
 }
 
 .sidenav .closebtn {
     position: absolute;
     top: 0;
     right: 25px;
     font-size: 36px;
     margin-left: 50px;
     cursor: pointer;
 }

 #main {
     transition: margin-left .5s;
     padding: 20px;
     /* text-align: center; */
     /* text-align: justify; */
     margin: 0 auto;
     width: 70%;
     line-height: 2;
 }

.main-page {
    min-height: 80vh;
}

 @media screen and (max-height: 450px) {
     .sidenav {
         padding-top: 12px;
     }

     .sidenav a {
         font-size: 18px;
     }
 }


 @media (max-width: 991px) {
    #main {
            transition: margin-left .5s;
            padding: 20px;
            /* text-align: center; */
            text-align: justify;
            margin: 0 auto;
            width: 95%;
            line-height: 2;
        }
 }