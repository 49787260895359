.login-form {
    margin: 100px 0;
}

.form-signin {
    max-width: 330px;
    padding: 15px;
}

.form-signin .form-floating:focus-within {
    z-index: 2;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.form-control:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid black;
}

/* ADMIN HOMEPAGE */



.adminhome-left {
    background-color: #010303;
    margin: 0 -30px;
    padding: 20px;
    height: 90vh;
    width: 280px;
    color: white;
}

.left-list {
    padding: 10px 30px;
    list-style: none;
}

.left-list a {
    text-decoration: none;
    color: white;
}

.left-list a:hover {
    color: #055052;
}

.admin-right {

}

.admin-details {
    box-shadow: 1px 0px 3px black;
}

.welcome-admin {
    float: right;
}

.admin-home {
    background-color: #ECECEC;
    /* margin: 0 -20px; */
}

.overview {
    margin-top: 15px;
}

.overview-card {
    width: 230px;
    height: 100px;
    border-radius: 5px;
    box-shadow: 0px 0px 0px black;
    background-color: white;
    }

.overview-icon {
    color: #055052;
}

.events {
    width: 850px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 0px black;
    background-color: white;
}

.officers {
    width: 650px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 0px black;
    background-color: white;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #dddddd;
}

.bulletin {
    width: 300px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 0px black;
    background-color: white;
}

.newsletter {
    width: 300px;
    height: 200px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 0px black;
    background-color: white;
}