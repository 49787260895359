li,
ul {
    list-style-type: none;
}

.thekey {
    text-decoration: underline;
    color: #008E89;
    margin-bottom: 8px;
}

.thekey:hover {
    color: black !important;
    text-decoration: underline;
}

.archive {
    margin-left: 10px;
    margin-top: 20px;
    padding-right: 15px;
}

h6 {
    color: black !important;

}

.newsletter-bulletin {
    margin-top: 70px;
}

.events-page {
    margin: 10px 0;
        border-left: 5px solid #008E89;
        margin-left: 10px;
}

.image-width {
    width: 100%;
}