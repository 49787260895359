.welcome {
    text-align: center;
}

.mission-belief {
    /* display: flex; */
    /* height: 300px; */
    /* flex: 1; */
}

.mission {
    background-color: black;
    padding: 30px 0;
}

.belief {
    background-color: #064635;
    padding: 10px 0;
}

.mission-text, .belief-text {
    color: white;
    /* padding-right: 100px; */
    line-height: 2;
}

.worship-info {
    background-color: #3A3845;
  height: 400px;
}

.mission-heading {
    color: white;
}


.mission-heading, .belief-heading {
    font-weight: 300px;
    display: inline-block;
    padding-bottom: 5px;
    position: relative;
}

.mission-heading:before, .belief-heading:before{
    content: "";
    position: absolute;
    width: 20%;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 3px solid #008E89;
}


.worship-days {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 0.7)) , url('../../images/TNSDA/Picure9.JPG');
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  /* height: 550px; */
  padding: 50px 0;
  text-align: justify;
}

.worship-days-end {
    text-align: center;
    color: white;
}

.worship-online-btn {
    color: white;
    background-color: #064635;
    outline: none;
}

.worship-online-btn:hover {
    color: white;
    border-radius: 20px;
}

.worship-days-section {
    font-weight: 300px;
    display: inline-block;
    padding-bottom: 5px;
    position: relative;
}

.worship-days-section:before{
    content: "";
    position: absolute;
    width: 20%;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 3px solid #008E89;
}


@media (min-width: 996px) {
    .welcome {
        text-align: center;
        padding: 100px 0;
    }
    .welcome-heading {
        padding: 0 15%;
        font-weight: bold;
    }
    .welcome-text {
        padding: 20px 20% 10px;
    }

    .mission, .belief {
    padding: 30px 100px;
}
.worship-days-text {
    padding: 15px 20%;
    font-size: 1.2rem;
    /* line-height: 2; */
}
}