.main-nav {
  background-color: #064635;
  padding-bottom: 5px;
  color: white;
  height: 70px;
}

.logo {
  position: relative;
  top: 10px;
  left: 20px;
  text-decoration: none;
  color: white;
}

.navbar-logo {
  position: relative;
  /* top: 5px; */
  /* left: 20px; */
  text-decoration: none;
  color: white;
}

.logo-img {
  height: 70px;
  margin: 0px 0 0 8px;
  float: left;
}

.logo-text {
  width: 90px;
  float: left;
  margin: 18px 0 0 -7px;
  color: #fff;
}

.church-logo {
  font-size: 10px;
  margin-top: -15px;
  width: 120px;
}

.links {
  display: flex;
  justify-content: right;
  padding-top: 25px;
  margin-right: 20px;
}

.links li {
  list-style-type: none;
  padding: 0px 15px;
  border-radius: 3px;
  /* padding-bottom: 5px; */
  }

.links li a {
  text-decoration: none;
  color: white;
}

.links li:hover {
  background-color: #146356;
color: #fff;
border-radius: 20px;
transition: all 0.2s ease-out;
}

/* MINISTRY AND MEDIA SUBLINKS */

.ministry, .media {
  position: relative;
}

.ministry-links {
  display: none;
  position: absolute;
  width: 120%;
  background-color: #064635;
  left: 0;
  padding-top: 3px;
  
}

.media-links {
  display: none;
  position: absolute;
  width: 110px;
  background-color: #064635;
  left: 0;
  padding-top: 3px;
  padding-bottom: 10px;
}

.ministry-links li:first-child, .media li:first-child {
  margin: 17px 0 0 -32px;
}

.ministry-links li, .media li{
  margin: 5px 0 0 -32px;
}

.ministry:hover .ministry-links, .media:hover .media-links{
  display: block;
}

.ministry-links li .ministry-sublink, .media-links li a{
  text-decoration: none;
  color: white;
}

.caret {
  padding-left: 5px;
  cursor: pointer;
}

.hamburger {
  display: flex;
  justify-content: right;
  display: none;
  cursor: pointer;
}

.sideNav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.3s;
  padding-top: 60px;
  text-align: center;
}

.sideNav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sideNav a:hover {
  color: #f1f1f1;
}

.sideNav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  cursor: pointer;
}

.openDropdown, .openMediaDropdown {
  display: block;
  transition: '0.3s'
}

.closeDropdown {
  display: none;
  transition: '0.3s'
}

.openDropdown a {
  font-size: 20px;
}

@media (max-width: 996px) {
.hamburger {
  display: block;        
  display: flex;
  justify-content: right;
  margin-right: 10px;
}   
  .links {
      display: none;
  }
}

@media screen and (max-height: 450px) {
  .sideNav {
      padding-top: 15px;
  }

  .sideNav a {
      font-size: 18px;
  }
} 