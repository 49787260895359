.church-background {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("../../images/TNSDA/church-building.jpg");
    background-position: center;
    background-size: cover;
    height: 450px;
}

.background {
    margin: -70px 40px 0;
    box-shadow: 0px 0px 3px black;
    background-color: white;
    /* height: 500px; */
}

.tnsda-intro {
    text-align: justify;
    line-height: 2;
}

.envelop-img {
    width: 95%;
}

.more-text {
    line-height: 2;
}

.payment-channels {
    background-color: #F7F7F7;
    padding: 10px 0px;
}

.envelop-btn {
    background-color: #064635;
    border: none;
    outline: none;
}

.envelop-btn:hover {
    border-radius: 20px;
}

.bible {
    text-align: center;
}

.bible-text {
    margin: 0 10%;
    line-height: 2;
}

.vision-links li {
    list-style-type: disc;
    line-height: 2;
}

 @media (max-width: 991px) {
    .church-background {
            background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("../../images/TNSDA/church-building.jpg");
            background-position: center;
            background-size: cover;
            height: 250px;
        }
    .background {
            margin: -50px 10px 0;
            box-shadow: 0px 0px 3px black;
            background-color: white;
            /* height: 500px; */
        }
 }