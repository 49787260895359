.main-header {
    background-image: linear-gradient(rgba(0, 0, 0, 0.51),rgba(0, 0, 0, 0.51)) , url('../../images/TNSDA/Picture10.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    animation: change 20s infinite ease-in-out;
}

.intro-text {
    font-weight: bold;
}

.header-content {
    padding : 25vh 2vh;
    text-align: center;
    color: white;
  }

  .watch-sermon {
    background-color: #064635;
    color: #fff;
  }

  .watch-sermon:hover {
    border-radius: 20px;
    color: #fff;
  }

  @keyframes change {
      0%
      {
        background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.7)) , url('../../images/TNSDA/Picture10.jpeg');
      }
      50%
      {
        background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.7)) , url('../../images/TNSDA/men.jpeg');
      }
      100%
      {
        background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.7)) , url('../../images/TNSDA/Picture12.jpg');
      }
  }