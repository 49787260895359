* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.church-name {
  padding-bottom: 300px;
}

.content {
  padding-top : 30vh;
  width : 100%;
  height: 80vh;
  display: inline-block;
  text-align: center;
}

.green-underline {
    font-weight: 300px;
    display: inline-block;
    padding-bottom: 5px;
    position: relative;
}

.green-underline:before{
    content: "";
    position: absolute;
    width: 20%;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 3px solid #008E89;
}

.mainButton {
  background-color: #064635;
  padding: 6px;
  color: white;
  border-radius: 4px;
}

.mainButton:hover {
  color: white;
  border-radius: 20px;
}

.page-not-found {
  margin: 200px auto;
  text-align: center;
}

.policy-list li {
  list-style-type: circle;
}