.footer-section {
    margin-top: 20px;
    background-color: #064635;
}

.footer-title {
    color: white;
    font-weight: 300;
    display: inline-block;
    padding-bottom: 5px;
    position: relative;
}

.footer-title:before{
    content: "";
    position: absolute;
    width: 50%;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid #008E89;
}

.footers p {
    color: white;
}

li > a {
    text-decoration: none;
    color: white;
}

li:hover > a:hover {
    text-decoration: none;
    color: white;
}

.external-link {
    text-decoration: none;
    color: white;
}

.external-link:hover {
    color: white;
}

.footer-fa {
    padding-left: 5px;
    font-size: 15px;
}

.footer-church-name {
    color: #fff;
}

.footers ul {line-height:30px;}

 .social {
     padding: 20px 10px 20px 0;
     color: white;
 }

 .footer-link {
    display: inline-block;
    position: relative;
 }

 .footer-link::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #008E89;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
 }

 .footer-link:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  