button {
  appearance: none;
  -webkit-appearance: none;

  padding: 10px;
  border: 0;
  background-color: #064635;
  color: #fff;
  width: auto;
  font-weight: 600;
  border-radius: 5px;
}

button:hover {
  border-radius: 20px;
}