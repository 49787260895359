.about-intro {
    margin: 70px 0;
    border-left: 5px solid #008E89;
    margin-left: 10px;

}

.about-us-intro {
    line-height: 2.5;
}

.about-top-image:hover {
    transform: scale(1.1);
}

.our-values {
    background-color: #F7F7F7;
    padding: 20px 5px;
}

.value-image {
    width: 100%;
    box-shadow: 0px 0px 6px black;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.value-description {
    margin-top: 10px;
    font-size: 1em;
    line-height: 2.5;
}

.more-values {
    margin-top: 100px;
}


@media (min-width: 996px) {
    .about-text {
        padding: 5px 20%;
        line-height: 2.5;
    }
    .value-description {
        margin-top: 30px;
    }  
}

@media (max-width: 996px) {
 .value-description {
    order: 1;
}
.about-us-intro {
    line-height: 2;
    padding-right: 20px;
}
.about-intro {
    margin: 30px 15px;
    border-left: 5px solid #008E89;
    margin-left: 10px;
}
}
