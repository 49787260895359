.mapouter {
    margin: 20px;
    width:90%; 
    box-shadow: 0px 0px 3px 2px #888888;
}

.map-layout {
    width: "550px";
    height: "500px"
}

.worship-with-us {
    font-weight: 300px;
    display: inline-block;
    padding-bottom: 5px;
    position: relative;
}

.worship-with-us:before{
    content: "";
    position: absolute;
    width: 20%;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 3px solid #008E89;
}