.page-bg {
    background-color: #F7F7F7;
}

.contact-form {
    background-color: white;
    box-shadow: 1px 1px 5px grey;
    margin-top: -20px;
    padding: 10px;
}

@media (min-width: 991px) {
    .contact-form {
            margin: -20px 50px 0 70px;
        }
}