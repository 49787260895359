*,
*:before,
*:after {
  box-sizing: 'border-box';
}
.form_style {
  /* max-width: 900px; */
  margin: auto;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
}

form {
  display: 'flex';
  flex-flow: 'row wrap';
  align-items: 'center';
}

input,
textarea {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 8px;
}

textarea {
  resize: vertical;
  width: 100%;
  height: 150px;
}

.form-group {
  padding: 10px;
}

a {
  color: #008E89;
  text-decoration: none;
}

.bible-study-links {
  margin-bottom: 40px;
}
.bible-study-links > p {
  margin-bottom: 5px;
}

.intro, h1 {
  text-align: center;
}

.contact-us, .prayer-request, .bible-study{
  /* margin-top: 135px; */
}

.form_contact_info {
  /* margin-top: 50px; */
}

.page-bg {
  background-color: #F7F7F7;
}

.request-form {
  background-color: white;
  box-shadow: 1px 1px 5px grey;
  margin-top: -20px;
  padding: 10px;
}

@media (min-width: 991px) {
  .request-form {
    margin: -20px 50px 0 70px;
  }
}