.popup {
    position: fixed;
    border-radius: 5px;
    z-index: 999;
    width: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: transparent;
    font-size: 40px;
    cursor: pointer;
}

.popup-image {
    text-align: center;
}

.main-popup-image {
    width: 100%;
        height: auto;
}

/* MEDIA QUERIES  */
/* Styles for iPhones and Android devices in portrait orientation */
@media only screen and (max-width: 480px) and (orientation: portrait) {
    .main-popup-image {
        width: 100vw;
        height: 300px;
    }

    .popup {
        position: fixed;
        border-radius: 5px;
        z-index: 999;
        width: 70%;
        top: 50%;
        left: 50%;
        transform: translate(-71%, -50%);
    }

    .close-button {
        position: absolute;
        top: -10px;
        right: -120px;
        border: none;
        background-color: transparent;
        font-size: 40px;
        cursor: pointer;
    }
}

/* Styles for iPhones and Android devices in landscape orientation */
@media only screen and (max-width: 480px) and (orientation: landscape) {
    .main-popup-image {
        width: 200%;
        height: 100%;
    }
}

/* Styles for iPads in portrait and landscape orientation */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .main-popup-image {
            width: 80vw;
            height: 100%;
        }
    
        .popup {
            position: fixed;
            border-radius: 5px;
            z-index: 999;
            width: 70%;
            top: 50%;
            left: 50%;
            transform: translate(-55%, -50%);
        }
    
        .close-button {
            position: absolute;
            top: -10px;
            right: -60px;
            border: none;
            background-color: transparent;
            font-size: 40px;
            cursor: pointer;
        }
}